import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { generateSearchQuery } from '../core/helpers/params-generator';
import { IResponseSuccess } from '../core/models/response-sucess.model';
import { LoadHousedWebReceiptRequest } from '../models';

@Injectable({
  providedIn: 'root',
})
export class HousedWebReceiptsService {
  constructor(private http: HttpClient) {}

  load(request: LoadHousedWebReceiptRequest) {
    return this.http.get<IResponseSuccess>(
      `properties/alloggiatiweb/receipts/?${generateSearchQuery(request)}`,
    );
  }
}
