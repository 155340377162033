import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { IPagination } from '../../core/models/api/pagination/pagination.model';
import { HousedWebReceipt } from '../../models';

export const featureAdapter: EntityAdapter<HousedWebReceipt> =
  createEntityAdapter<HousedWebReceipt>({
    selectId: (model) => model.id,
  });

export interface State extends EntityState<HousedWebReceipt> {
  isLoading?: boolean;
  error?: any;
  pagination: IPagination;
}

export const initialState: State = featureAdapter.getInitialState({
  isLoading: false,
  error: null,
  pagination: null,
});
