import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { ErrorHandlerService } from '../../core/services/error-handler.service';
import { HousedWebReceiptsService } from '../../services/housed-web-receipts.service';

import * as fromActions from './actions';

@Injectable()
export class HousedWebReceiptsStoreEffects {
  constructor(
    private dataService: HousedWebReceiptsService,
    private actions$: Actions,
    private errorHandler: ErrorHandlerService,
  ) {}

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadRequest),
      switchMap(({ request }) =>
        this.dataService.load(request).pipe(
          map(({ data, meta }) =>
            fromActions.loadSuccess({
              items: data,
              pagination: meta.pagination,
            }),
          ),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.loadFailure(error));
          }),
        ),
      ),
    ),
  );
}
