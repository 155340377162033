import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { HousedWebReceiptsStoreEffects } from './effects';
import { housedWebReceiptsReducer } from './reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('housedWebReceipts', housedWebReceiptsReducer),
    EffectsModule.forFeature([HousedWebReceiptsStoreEffects]),
  ],
})
export class HousedWebReceiptsStoreModule {}
